<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 46 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Equalizer</title>
    <g clip-path="url(#clip0_2285_8100)">
      <path
        d="M19.0523 40H26.6731V8H19.0523V40ZM7.62109 40H15.2419V24H7.62109V40ZM30.4835 18V40H38.1044V18H30.4835Z"
        :fill="color"
      />
      <path
        d="M19.0523 40H26.6731V8H19.0523V40ZM7.62109 40H15.2419V24H7.62109V40ZM30.4835 18V40H38.1044V18H30.4835Z"
        fill="url(#paint0_linear_2285_8100)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_2285_8100"
        x1="217.49"
        y1="-121.455"
        x2="24.1602"
        y2="48.4418"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#6E19F0" />
        <stop
          offset="1"
          stop-color="#6E19F0"
          stop-opacity="0"
        />
      </linearGradient>
      <clipPath id="clip0_2285_8100">
        <rect
          width="45.7249"
          height="48"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'ic-equalizer',
  props: {
    size: {
      type: Number,
    },
    color: {
      type: String,
    },
  },
}
</script>
